import React from "react";
import DialogScreen from "../../components/dialog";

type Props = {}

const SellClosedPage: React.FC<Props> = () => {
    return <DialogScreen>
        <div className="info-dialog">
            <h2>Sprzedaż bombek zakończona</h2>
            <p>Zapraszamy na wielki finał 17.12</p>
        </div>
    </DialogScreen>
}

export default SellClosedPage;
