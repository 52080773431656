import { queryCache, useMutation } from "react-query";
import Network from "../../network";

type ILogin = {
    email: string
}

export default function useLogin() {

    const [login, {isLoading, isError, reset}] = useMutation<any, any, ILogin>(({email}) => {
        return Network.login(email)
    }, {
        onSuccess: () => {
            queryCache.invalidateQueries('config');
        }
    })

    return {
        login,
        reset,
        isLoading,
        isError
    }
}
