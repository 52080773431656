import { queryCache, useMutation } from "react-query"
import Network from "../../network"

type IBuy = {
    item: number,
    name: string,
    email: string,
    phone: string,
    workerID?: string,
    dreamTitle: string,
    dreamDescription: string,
    dreamValue: number,
    fixedItemPrice?: 1 | 20 | 'custom',
    customDreamPrice?: number,
    workshop: string,
    company?: string
}

export default function useBuy() {
    const [buy, { error, isLoading, reset }] = useMutation<any, any, IBuy>((params) => {
        const {item,
            name,
            email,
            phone,
            workerID,
            dreamTitle,
            dreamDescription,
            dreamValue,
            fixedItemPrice,
            customDreamPrice,
            workshop,
            company
        } = params;

        return Network.buy({
            item,
            email,
            name,
            phone,
            workerID,
            dreamTitle,
            dreamDescription,
            dreamValue,
            itemPrice: fixedItemPrice === 'custom' ? customDreamPrice : fixedItemPrice,
            workshop,
            company
        })
    }, {
        onSuccess: (data) => {
            queryCache.invalidateQueries('items');
        },
        onError: (error) => {
            queryCache.invalidateQueries('items');
        }
    });

    return {
        isLoading,
        buy,
        error,
        reset
    }
}
