import makeStore from "@mborecki/react-create-store";
import { User } from "../user";
import { EVENT_PHASE } from "./hooks/use-event-phase";
import reducer from "./reducer";

export type ConfigModuleState = {
    isLoading: boolean,
    user: User | null,
    debugMode: 'none' | 'phase' | 'time',
    forceEventPhase?: EVENT_PHASE | null,
    purchasedCount: number
}

const initConfigModuleState: ConfigModuleState = {
    isLoading: false,
    user: null,
    debugMode: 'none',
    purchasedCount: 0
}

export enum CONFIG_MODULE_ACTION {
    FETCH,
    FETCH_SUCCESS,
    SET_DEBUG_MODE
}

export type ConfigModuleAction = {
    type: CONFIG_MODULE_ACTION,
    payload?: any
}

const [
    ConfigModuleProvider,
    useConfigModuleDispatch,
    useConfigModuleState
] = makeStore(reducer, initConfigModuleState, 'tm_config')


export {
    ConfigModuleProvider,
    useConfigModuleDispatch,
    useConfigModuleState
}
