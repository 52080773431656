import React from "react";
import { useParams } from "react-router";
import Bombki from "../../components/bombki";
import Bombki2 from "../../components/bombki2";
import Bombki3 from "../../components/bombki3";
import './style.scss';

type Props = {}

const CatalogPage: React.FC<Props> = () => {

    const { page } = useParams<{ page: string }>();

    const pageID = parseInt(page)

    const showType1 = ((pageID % 10) !== 0) && pageID % 2 === 0;
    const showType2 = (pageID % 10) === 0;
    const showType3 = pageID % 2 === 1;


    return <div className="bombki-page">
        <h2>Wybierz bombkę</h2>
        {showType1 && <Bombki page={pageID} />}
        {showType2 && <Bombki2 page={pageID} />}
        {showType3 && <Bombki3 page={pageID} />}
    </div>
}

export default CatalogPage;
