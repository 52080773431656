
import { Item } from "../../../modules/network";
import BombkiApp from "./app";

export type AppConfig = {
    container: any,
    items: Item[],
    onItemSelected: (index: number) => void
}

const BASE_WIDTH = 1214;
const BASE_HEIGHT = 681;

export default function createApp(config: AppConfig) {

    const app = new BombkiApp(BASE_WIDTH, BASE_HEIGHT, 0x000000, config)

    config.container.appendChild(app.view);
    app.gameResize();

    // ====================== //

    return {
        destroy: () => {
            app.destroy();
        }
    };
}
