import React, { useEffect } from "react";
import { useHistory } from "react-router";
import DialogScreen from "../../components/dialog";
import LoginForm from "../../components/login-form";
import useIsLogged from "../../modules/user/hooks/use-is-logged";
import { getMainViewRoute } from "../routes";
import './style.scss';

type Props = {}

const LoginPage: React.FC<Props> = () => {

    const { isLogged } = useIsLogged();
    const history = useHistory();

    useEffect(() => {
        if (isLogged) {
            history.replace(getMainViewRoute());
        }
    }, [history, isLogged])

    return (
        <DialogScreen>
            <LoginForm />
        </DialogScreen>
    )
}

export default LoginPage;
