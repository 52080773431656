import { useQuery } from "react-query";
import { CONFIG_MODULE_ACTION, useConfigModuleDispatch, useConfigModuleState } from "..";
import Network from "../../network";

export default function useConfig() {
    const {user, forceEventPhase, debugMode, purchasedCount} = useConfigModuleState();
    const dispatch = useConfigModuleDispatch();

    const { isLoading, data } = useQuery('config', () => {
        dispatch({
            type: CONFIG_MODULE_ACTION.FETCH
        });
        return Network.getConfig();
    }, {
        staleTime: Infinity,
        onSuccess: (data: { user?: any, serverTime: number, purchasedCount: number }) => {

            dispatch({
                type: CONFIG_MODULE_ACTION.FETCH_SUCCESS,
                payload: {
                    user: data?.user,
                    purchasedCount: data?.purchasedCount || 0
                }
            })
        }

    });

    return {
        user,
        serverTime: (data?.serverTime || 0)*1000 || Date.now(),
        forceEventPhase: debugMode === 'phase' ? forceEventPhase : null,
        isLoading,
        purchasedCount
    };
}
