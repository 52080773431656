import Axios from "axios";

const URLS = {
    CONFIG: '/api/config',
    LOGIN: '/api/login',
    LOGOUT: '/api/logout',
    GET_ITEMS: '/api/items',
    BUY: '/api/buy',
    NEWS: '/api/news'
}

export type Item = {
    id: number,
    avaiable: boolean
}

export type News = {
    title: string,
    listImage: string,
    contentImage: string,
    text: string,
    listImageRatio: number,
}

function modUrl(url: string) {
    // return 'https://choinka.borek224.usermd.net' + url;
    return url;
}

function getConfig() {
    return Axios.get(modUrl(URLS.CONFIG), {
    withCredentials: true
    })
        .then(response => {
            const user = response.data.user;

            if (user) {
                return {
                    purchasedCount: response.data.purchasedCount || 0,
                    serverTime: response.data.time || 0,
                    isLogged: true,
                    user
                }
            }

            return {
                purchasedCount: response.data.purchasedCount || 0,
                serverTime: response.data.time || 0,
                isLogged: false
            };
        })
        .catch(response => {
            return {
                serverTime: Date.now(),
                purchasedCount: 0,
                user: null
            }
        })
}

function login(email: string) {
    return Axios.post(modUrl(URLS.LOGIN), {
        login: email
    }, {
        withCredentials: true
    })
        .then(response => { })
}

function logout() {
    return Axios.get(modUrl(URLS.LOGOUT), {
        withCredentials: true
    });
}

function getItems(): Promise<Item[]> {
    return Axios.get(modUrl(URLS.GET_ITEMS), {
        withCredentials: true
    })
        .then(response => {
            return response.data.map((v: any) => {
                return {
                    ...v
                }
            });
        })
}

function getNews(): Promise<News[]> {
    return Axios.get(modUrl(URLS.NEWS), {
        withCredentials: true
    })
        .then(response => {
            return (response.data || []).reverse();
        })
}

type buyRequestParams = {
    item: number,
    name: string,
    email: string,
    phone: string,
    workerID?: string,
    dreamTitle: string,
    dreamDescription: string,
    dreamValue: number,
    itemPrice?: number,
    workshop: string,
    company?: string
}

function buy({ item, name, email, phone, workerID, dreamTitle, dreamValue, dreamDescription, itemPrice, workshop, company }: buyRequestParams): Promise<any> {
    return Axios.post(modUrl(URLS.BUY), {
        item,
        name,
        email,
        phone,
        workerID,
        dreamTitle,
        dreamValue,
        dreamDescription,
        itemPrice,
        workshop,
        company
    }, {
        withCredentials: true,
    })
        .catch(error => {
            throw error.response.data
        })
}

const Network = {
    getConfig,
    login,
    logout,
    getItems,
    buy,
    getNews
}

export default Network;
