import React, { useRef } from "react";
import styled from "styled-components";
import ChoinkaWidget from "./widget";

type Props = {}

const Container = styled.div``;

const Choinka: React.FC<Props> = () => {
    const widgetRef = useRef<any>(null);

    return <Container>
        <ChoinkaWidget ref={widgetRef} />
    </Container>
}

export default Choinka;
