import * as PIXI from 'pixi.js';
import { ChoinkaPage } from "../choinka-page";

export default class ChoinkaPage1 extends ChoinkaPage {
    constructor (resources: Partial<Record<string, PIXI.LoaderResource>>) {
        super(resources);

        if (resources['choinka-1']) {
            const mainSprite = new PIXI.Sprite(resources['choinka-1']?.texture)

            this.addChild(mainSprite);
            mainSprite.zIndex = 0;
        }

        [
            {
                textureId: 'zone-0-09.png',
                poly: new PIXI.Polygon([
                    56,0,
                    0,200,
                    131,200,
                    60, 0
                ]),
                x: 895,
                y: 180,
                popupOnLeft: false,
                popupLineStart: 990
            },

            {
                textureId: 'zone-0-08.png',
                poly: new PIXI.Polygon([
                    57,0,
                    0,222,
                    118,222,
                    118, 0
                ]),
                x: 842,
                y: 382,
                popupOnLeft: true,
                popupLineStart: 960
            },

            {
                textureId: 'zone-0-07.png',
                poly: new PIXI.Polygon([
                    0,0,
                    0,222,
                    100,222,
                    50, 0
                ]),
                x: 962,
                y: 382,
                popupOnLeft: false,
                popupLineStart: 1045
            },

            {
                textureId: 'zone-0-06.png',
                poly: new PIXI.Polygon([
                    55,0,
                    175,0,
                    175,216,
                    0, 216
                ]),
                x: 760,
                y: 607,
                popupOnLeft: true,
                popupLineStart: 960
            },

            {
                textureId: 'zone-0-05.png',
                poly: new PIXI.Polygon([
                    0,0,
                    0,216,
                    150,216,
                    100, 0
                ]),
                x: 962,
                y: 607,
                popupOnLeft: false,
                popupLineStart: 1105
            },

            {
                textureId: 'zone-0-04.png',
                poly: new PIXI.Polygon([
                    55,0,
                    234,0,
                    234,238,
                    0, 238
                ]),
                x: 725,
                y: 826,
                popupOnLeft: true,
                popupLineStart: 960
            },

            {
                textureId: 'zone-0-03.png',
                poly: new PIXI.Polygon([
                    0,0,
                    0,238,
                    200,238,
                    150, 0
                ]),
                x: 962,
                y: 826,
                popupOnLeft: false,
                popupLineStart: 1140
            },

            {
                textureId: 'zone-0-01.png',
                poly: new PIXI.Polygon([
                    50,0,
                    0,263,
                    175,263,
                    175, 0
                ]),
                x: 685,
                y: 1066,
                popupOnLeft: true,
                popupLineStart: 865
            },

            {
                textureId: 'zone-0-02.png',
                poly: new PIXI.Polygon([
                    0,0,
                    0,263,
                    190,263,
                    190, 0
                ]),
                x: 865,
                y: 1066,
                popupOnLeft: true,
                popupLineStart: 1060
            },

            {
                textureId: 'zone-0-00.png',
                poly: new PIXI.Polygon([
                    0,0,
                    0,263,
                    175,263,
                    110, 0
                ]),
                x: 1058,
                y: 1066,
                popupOnLeft: false,
                popupLineStart: 1215
            },
        ].forEach((d, index) => {
            this.addZone(d, index);
        });
    }
}
