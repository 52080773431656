import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useBuy from "../../modules/items/hooks/use-buy";
import { User } from "../../modules/user";
import { ErrorMessage } from '@hookform/error-message';
import { useHistory } from "react-router";
import { getMainViewRoute } from "../../routes/routes";

import './style.scss';
import DialogScreen from "../dialog";
import Loader from "../loader";
import Popup from "./popup";
import { queryCache } from "react-query";

type Props = {
    id: number,
    user: User
}

const BuyForm: React.FC<Props> = ({ id, user }) => {

    const history = useHistory();
    const { buy, isLoading, error, reset } = useBuy();

    const [formData, setFormData] = useState<any>();

    const [phase, setPhase] = useState<'form' | 'summary' | 'confirm' | 'limit_error' | 'already_purchased_error' | 'error' | 'loading'>('form');

    const showLoader = isLoading;
    const showForm = !showLoader && phase === 'form';
    const showConfirm = !showLoader && phase === 'confirm';
    const showSummary = !showLoader && phase === 'summary';
    const showError = !showLoader && phase === 'error';
    const showLimitError = !showLoader && phase === 'limit_error'
    const showAlreadyPurchasedError = !showLoader && phase === 'already_purchased_error'

    useEffect(() => {
        if (error) {
            switch (error.code) {
                case 13001:
                    setPhase('limit_error');
                    break;

                case 13003:
                    setPhase('already_purchased_error');
                    break;

                default:
                    setPhase('error');
            }
        }
    }, [error]);

    const isWorker = user.group === 'worker';
    const isPress = user.group === 'press';

    const sendBuy = useCallback((data) => {
        buy({
            ...data,
            customDreamPrice: data.customDreamPrice ? parseFloat(data.customDreamPrice.replace(',', '.')) : data.customDreamPrice,
            dreamValue: data.dreamValue ? parseFloat(data.dreamValue.replace(',', '.')) : data.dreamValue,
            item: id,
        }, {
            onSuccess: () => {
                setPhase('summary');
            },
            onError: (error) => {

                if (error.code === 13001) {
                    // setShowLimitError(true);
                    return;
                }

                setPhase('error');
            }
        })

    }, [buy, setPhase, id])

    const { register, handleSubmit, watch, errors } = useForm();
    const onSubmit = useCallback((data) => {
        if (isWorker) {
            setFormData(data);
            setPhase('confirm');
        } else {
            reset();
            sendBuy(data);
        }
    }, [setFormData, setPhase, isWorker, sendBuy, reset]);

    const confirmPurchase = useCallback(() => {
        reset();
        sendBuy(formData);
    }, [sendBuy, formData, reset]);

    const cancelPurchase = useCallback(() => {
        setPhase('form');
    }, [setPhase]);

    const renderError = useCallback(({ message }) => {
        return <p className="error">{message || 'Proszę podać poprawną wartość'}</p>
    }, [])

    const onClickInSummary = useCallback(() => {
        history.push(getMainViewRoute());
    }, [history])

    const onClickInError = useCallback(() => {
        queryCache.invalidateQueries('config');
        history.push(getMainViewRoute());
    }, [history])

    const fixedItemPrice = watch('fixedItemPrice');
    const showCustomDreamPriceField = (fixedItemPrice === 'custom');

    return <>
        {showForm &&
            <main className="buy-page page">
                <div className="buy-page-wrapper">
                    <div className="page-content">
                        <header>
                            <h1>Bombka< br /> nr {id}</h1>
                        </header><div className='buy-form'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <label className='form-section'>
                                    <div className="field-wrapper">
                                        <input type='text' name='name' ref={register({ required: "Pole wymagane", maxLength: { value: 255, message: 'Maksymalna długość 255 znaków' } })} />
                                    </div>
                                    <span>Imie i nazwisko</span>
                                    <ErrorMessage errors={errors} name="name" render={renderError} />
                                </label>
                                <label className='form-section'>
                                    <div className="field-wrapper">
                                        <input type='text' name='email' defaultValue={user.email} ref={register({ required: "Pole wymagane", maxLength: 255 })} />
                                    </div>
                                    <span>Email</span>
                                    <ErrorMessage errors={errors} name="email" render={renderError} />
                                </label>
                                <label className='form-section'>
                                    <div className="field-wrapper">
                                        <input type='text' name='phone' ref={register({ required: 'Pole wymagane', maxLength: 20, minLength: 6 })} />
                                    </div>
                                    <span>Numer telefonu</span>
                                    <ErrorMessage errors={errors} name="phone" render={renderError} />
                                </label>
                                {isWorker && <label className='form-section'>
                                    <div className="field-wrapper">
                                        <input type='text' name='workerID' ref={register({ required: 'Pole wymagane', maxLength: { value: 255, message: 'Maksymalna długość 255 znaków' } })} />
                                    </div>
                                    <span>Numer ID</span>
                                    <ErrorMessage errors={errors} name="workerID" render={renderError} />
                                </label>}
                                {isPress && <label className='form-section'>
                                    <div className="field-wrapper">
                                        <input type="text" name='company' ref={register({ required: 'Pole wymagane' })} />
                                    </div>
                                    <span>Nazwa redakcji</span>
                                    <ErrorMessage errors={errors} name="company" render={renderError} />
                                </label>}
                                <label className='form-section wide'>
                                    <div className="field-wrapper">
                                        <input type='text' name='dreamTitle' ref={register({ required: 'Pole wymagane', maxLength: { value: 255, message: 'Maksymalna długość 255 znaków' } })} />
                                        <div className="popup-anchor">
                                            <Popup message={'W TYM POLU WPISZ NAZWĘ MARZENIA,<br /> KTÓRE CHCESZ SPEŁNIĆ,<br /> NP. WSPARCIE SCHRONISKA,<br /> WSPARCIE DOMU DZIECKA W WARSZAWIE itd.'} />
                                        </div>
                                    </div>
                                    <span>Nazwa marzenia, które chcesz spełnić</span>
                                    <ErrorMessage errors={errors} name="dreamTitle" render={renderError} />
                                </label>
                                <label className='form-section extra-wide extra-margin'>
                                    <div className="field-wrapper">
                                        <textarea name='dreamDescription' ref={register({ required: 'Pole wymagane', maxLength: { value: 1500, message: 'Maksymalna długość 1500 znaków' } })} />
                                        <div className="popup-anchor">
                                            <Popup message={'NP. CHCĘ WESPRZEĆ SCHRONISKO <br />W PIASECZNIE W POSTACI KARMY<br />I KOCÓW DLA ZWIERZĄT NA ZIMĘ'} />
                                        </div>
                                    </div>
                                    <span>Opis marzenia *do max 1500 znaków</span>
                                    <ErrorMessage errors={errors} name="dreamDescription" render={renderError} />
                                </label>
                                <label className='form-section big-extra-margin wide'>
                                    <div className="field-wrapper">
                                        <input type='text' className='narrow' name='dreamValue' ref={register({
                                            required: true, pattern: /^[0-9]+([,.][0-9]{1,2})?$/, validate: (value: string) => {
                                                return parseFloat(value) <= 4000 ? true : 'wartość nie może przekraczać 4000 złotych';
                                            }
                                        })} />
                                    </div>
                                    <span>Szacunkowa wartość marzenia *wartość nie może przekraczać 4000 złotych</span>
                                    <ErrorMessage errors={errors} name="dreamValue" render={renderError} />
                                </label>
                                {isWorker && <div className="form-section">
                                    <div>

                                        <div className="price-select">
                                            <div className="left">
                                                <label className='price-variant'>
                                                    <input type="radio" name="fixedItemPrice" value={5} ref={register({ required: true })} /><span className="radio-label">5ZŁ</span>
                                                </label>
                                                <label className='price-variant'>
                                                    <input type="radio" name="fixedItemPrice" value={20} ref={register({ required: true })} /><span className="radio-label">20ZŁ</span>
                                                </label>
                                            </div>
                                            <div className="right">
                                                <div className='price-variant'>
                                                    <div className="field-wrapper-2">
                                                        <input type="radio" id="customPriceButton" name="fixedItemPrice" value='custom' ref={register({ required: true })} /><label htmlFor="customPriceButton" className='radio-label custom-price'>Inna kwota</label>
                                                        <div className="popup-anchor">
                                                            <Popup message={'Kwota powyżej 20zł'} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span>Deklarowana kwota na zakup bombki</span>
                                    </div>
                                    <ErrorMessage errors={errors} name="fixedItemPrice" render={renderError} />
                                    {showCustomDreamPriceField && <label className='form-section'>
                                        <input type="text" className='narrow' name="customDreamPrice" ref={register({
                                            required: true, pattern: /^[0-9]+([,.][0-9]{1,2})?$/, validate: (value: string) => {
                                                return parseFloat(value) > 20 ? true : 'kwota musi być większa niż 20 złotych';
                                            }
                                        })} />
                                        <span>Podaj dokładną kwotę</span>
                                        <ErrorMessage errors={errors} name="customDreamPrice" render={renderError} />
                                    </label>}
                                </div>}
                                <p className='mdm-info'>
                                    Zostając <strong>Magenta Dream Makerem</strong> niesiesz dobro. A dobro wraca!<br />
                Dlatego my również przygotowaliśmy coś miłego dla ciebie. Weź udział w wyjątkowym spotkaniu na żywo.
            </p>
                                <div className="workshops">
                                    <p>Wybierz:</p>
                                    <div className="workshop-list">

                                        <label className='workshop'>
                                            <div className="img">
                                                <img src="/images/buy/workshop-1.png" alt="" />
                                            </div>
                                            <input type="radio" name="workshop" value="dekoracje" ref={register({ required: true })} />
                                            <div className="option">
                                                <span>Świąteczne dekoracje kreatywne z Panią Gadżet</span>
                                            </div>
                                        </label>
                                        <label className='workshop'>
                                            <div className="img">
                                                <img src="/images/buy/workshop-2.png" alt="" />
                                            </div>
                                            <input type="radio" name="workshop" value="kulinarne" ref={register({ required: true })} />
                                            <div className="option">
                                                <span>Świąteczne gotowanie z Robertem Makłowiczem</span>
                                            </div>
                                        </label>
                                        <label className='workshop'>
                                            <div className="img">
                                                <img src="/images/buy/workshop-3.png" alt="" />
                                            </div>
                                            <input type="radio" name="workshop" value="spotkanie" ref={register({ required: 'Proszę wybrać warsztaty' })} />
                                            <div className="option">
                                                <span>Wspólnie o świętach -{'\u00a0'}spotkanie z{'\u00a0'}Patrycją Markowską i tatą</span>
                                            </div>
                                        </label>
                                    </div>
                                    <ErrorMessage errors={errors} name="workshop" render={renderError} />
                                </div>
                                <input type='submit' value={isWorker ? 'Kupuję' : "Potwierdzam"} />
                            </form>



                        </div>

                    </div>

                </div>
            </main>
        }
        {showConfirm && <DialogScreen>
            <div className="info-dialog">
                <p>
                    Numer twojej bombki: <strong>{id}</strong><br />
                    Kwota deklarowana: <strong>{formData.fixedItemPrice === 'custom' ? formData.customDreamPrice : formData.fixedItemPrice}</strong>
                </p>
                <div className="buttons">
                    <button className='secondary' onClick={cancelPurchase}>Anuluj</button>
                    <button onClick={confirmPurchase}>Potwierdź</button>
                </div>
            </div>
        </DialogScreen>}

        {showSummary && <DialogScreen>
            <div className="info-dialog">
                <h2>
                    Gratulujemy!<br />
                    <span>Zostałeś Magenta Dream Makerem!</span>
                </h2>
                <p>Informacje o finale oraz potwierdzenie zakupu bombki wysłaliśmy na twojego maila!</p>
                <div className="buttons">
                    <button onClick={onClickInSummary}>OK</button>
                </div>
            </div>
        </DialogScreen>}

        {showError && <DialogScreen>
            <div className='error-dialog'>
                <h2>Wystąpił nieoczekiwany błąd</h2>
                <div className="buttons">
                    <button onClick={onClickInError}>Powrót</button>
                </div>
            </div>
        </DialogScreen>}

        {showLimitError && <DialogScreen>
            <div className='error-dialog'>
                <h2>Ups! Wykorzystałeś limit bombek</h2>
                <p>
                    Każdy z Magenta Dream Makerów może kupić 3 bombki. Wygląda na to, że już je masz!<br />
                    Do zobaczenia na finame 17.12
                </p>
                <div className="buttons">
                    <button onClick={onClickInError}>Ok</button>
                </div>
            </div>
        </DialogScreen>}

        {showAlreadyPurchasedError && <DialogScreen>
            <div className='error-dialog'>
                <h2>Ups! Ktoś właśnie kupił tę bombkę.</h2>
                <p>Spróbuj ponownie</p>
                <div className="buttons">
                    <button className="bigger" onClick={onClickInError}>Wybierz inną bombkę</button>
                </div>
            </div>
        </DialogScreen>}

        {showLoader && <DialogScreen>
            <Loader />
        </DialogScreen>}
    </>
}

export default BuyForm;
