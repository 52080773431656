import React, { useRef } from "react";
import styled from "styled-components";
import usePage from "../../modules/items/hooks/use-page";
import BombkiWidget from "./widget";

type Props = {
    page: number
}

const Container = styled.div`
    max-width: 1214px;
    width: 100%;
    margin: 0 auto;
`;

const Bombki2: React.FC<Props> = ({ page }) => {
    const widgetRef = useRef<any>(null);

    const { items, isLoading } = usePage(page);

    return <Container>
        {items.length && !isLoading && <BombkiWidget ref={widgetRef} items={items} />}
    </Container>
}

export default Bombki2;
