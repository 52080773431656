import { useUser } from "./use-user";

export default function() {
    const {user, isLoading} = useUser();

    return {
        isLogged: Boolean(user),
        isLoading
    }
}
