import React, { useEffect } from "react";
import styled from "styled-components";

type Props = {
    embedUrl: string
}

const Container = styled.div`
    flex: 1 1 auto;

    background: url('/images/bg/bombki-1.png') left bottom 50px no-repeat,
                url('/images/bg/bombki-2.png') right bottom 30px no-repeat;

    align-self: center;

    width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

    .wrapper-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 75%;
        margin: 0 auto;
        position: relative;
    }

    .wrapper {
        width: 100%;
        padding-top: 56%;
        position: relative;
    }

    .video-container {

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`;

const StreamingPage: React.FC<Props> = ({ embedUrl }) => {

    useEffect(() => {
        const root = document.getElementById('root');

        if (root) {
            root.style.height = '100vh';

            return () => {
                root.style.height = 'auto';
            }
        }
    }, [])

    return <Container>
        <div className="wrapper-2">
            <div className="wrapper">
                <div className="video-container">
                    <div dangerouslySetInnerHTML={{ __html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="${embedUrl}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>` }}>

                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default StreamingPage;
