import React from "react";
import usePurchasedCount from "../../modules/config/hooks/use-purchased-count";

type Props = {};

const PurchasedCounter: React.FC<Props> = () => {
    const { isLoading, purchasedCount } = usePurchasedCount();

    const showCounter = !isLoading && purchasedCount;


    return <>
        {showCounter && <div className="counter">
            <div className="text">Jest nas już</div>
            <div className="value">{purchasedCount}</div>
        </div>}
    </>
}

export default PurchasedCounter;
