import * as PIXI from 'pixi.js';

export default class Zone extends PIXI.Container {
    constructor(private spriteTexture: PIXI.Texture, private area: PIXI.Polygon) {
        super();

        this.hitArea = area;

        const debug = new PIXI.Graphics();

        debug.beginFill(0x00ff00);
        debug.drawPolygon(area);
        debug.endFill();

        // this.addChild(debug)

        //------------------------------------------

        const sprite = new PIXI.Sprite(spriteTexture);

        this.addChild(sprite);

        this.interactive = true;
        sprite.visible = false;
        this.cursor = 'pointer';

        this.on('pointerover', () => {

            sprite.visible = true;
        });

        this.on('pointerout', () => {
            sprite.visible = false;
        });
    }
}
