import React, { useCallback, useState } from "react";
import styled from "styled-components";

type Props = {
    message: string;
}

const Container = styled.div`

line-height: 1em;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    .popup-box {
        position: absolute;

        bottom: 100%;
        right: -50%;


        width: 100vw;
        pointer-events: none;

        .content {
        pointer-events: all;
            float: right;
            font-family: tele-grotesknor, Arial, sans-serif;
            color: black;
            font-size: 1rem;
            padding: .5em;
            border-radius: .5em;
            background: white;
            border: 1px solid #c21572;
            text-align: center;
        }
    }
`;

const Popup: React.FC<Props> = ({ message }) => {

    const [showPopup, setShowPopup] = useState(false);
    const [lockPopup, setLockPopup] = useState(false);

    const isPopupDisplayed = showPopup || lockPopup;

    const onMouseOver = useCallback(() => {
        setShowPopup(true)
    }, [setShowPopup]);

    const onMouseOut = useCallback(() => {
        setShowPopup(false)
    }, [setShowPopup]);

    const onClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setLockPopup(true)
    }, [setLockPopup]);

    const onMesageClick = useCallback(() => {
        setLockPopup(false)
        setShowPopup(false)
    }, [setLockPopup, setShowPopup]);

    return <Container onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
        <span className="anchor" onClick={onClick}>?</span>
        {isPopupDisplayed && <div className="popup-box">
            <div className="content" dangerouslySetInnerHTML={{ __html: message }} onClick={onMesageClick}></div>
        </div>
        }
    </Container>
}

export default Popup;
