import * as PIXI from 'pixi.js'
import Bombka from './bombka';
import { AppConfig } from './create-app';

export default class BombkiApp extends PIXI.Application {
    private baseWidth: number;
    private baseHeight: number;
    private baseRatio: number;
    private scaleRatio = 1;
    private resources?: Partial<Record<string, PIXI.LoaderResource>>;

    constructor(width: number, height: number, backgroundColor: number, private config: AppConfig) {
        super({
            width,
            height,
            backgroundColor
        });

        this.baseHeight = height;
        this.baseWidth = width;
        this.baseRatio = height / width;

        window.addEventListener('resize', this.gameResize)
        this.gameResize();

        this.loadTextures().then((resources) => {
            this.resources = resources;
            this.initGame()
        })
    }

    private loadTextures(): Promise<Partial<Record<string, PIXI.LoaderResource>>> {
        return new Promise((resolve) => {

            [
                ['background', '/images/bombki/widget-background.png'],
                ['bombki', '/images/bombki/bombki.json']
            ].forEach(([key, src]) => {
                this.loader.add(key, src);
            })

            this.loader.load((loader, resource) => {
                resolve(resource)
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })
        })

    }

    private bombki: Bombka[] = [];

    private initGame() {
        if (this.resources?.background) {
            const sprite = new PIXI.Sprite(this.resources.background.texture);

            this.stage.addChild(sprite);
        }


        if (this.resources?.bombki) {
            const sheet = this.resources?.bombki.spritesheet;


            if (sheet) {
                [
                    { id: '00', x: 178, y: 133 },
                    { id: '01', x: 177, y: 132 },
                    { id: '02', x: 323, y: 55 },
                    { id: '03', x: 368, y: 153 },
                    { id: '04', x: 401, y: 68 },
                    { id: '05', x: 541, y: 162 },
                    { id: '06', x: 671, y: 55 },
                    { id: '07', x: 767, y: 90 },
                    { id: '08', x: 715, y: 172 },
                    { id: '09', x: 658, y: 252 },
                    { id: '10', x: 425, y: 285 },
                    { id: '11', x: 300, y: 268 },
                    { id: '12', x: 175, y: 313 },
                    { id: '13', x: 88, y: 255 },
                    { id: '14', x: 46, y: 308 },
                    { id: '15', x: 51, y: 407 },
                    { id: '16', x: 151, y: 406 },
                    { id: '17', x: 152, y: 480 },
                    { id: '18', x: 293, y: 441 },
                    { id: '19', x: 224, y: 610 },
                    { id: '20', x: 316, y: 601 },
                    { id: '21', x: 396, y: 642 },
                    { id: '22', x: 414, y: 522 },
                    { id: '23', x: 516, y: 488 },
                    { id: '24', x: 488, y: 558 },
                    { id: '25', x: 528, y: 607 },
                    { id: '26', x: 636, y: 532 },
                    { id: '27', x: 750, y: 590 },
                    { id: '28', x: 742, y: 460 },
                    { id: '29', x: 807, y: 550 },
                    { id: '30', x: 867, y: 623 },
                    { id: '31', x: 972, y: 565 },
                    { id: '32', x: 1076, y: 580 },
                    { id: '33', x: 1133, y: 420 },
                    { id: '34', x: 989, y: 363 },
                    { id: '35', x: 1038, y: 222 },
                    { id: '36', x: 979, y: 456 },
                    { id: '37', x: 856, y: 480 },
                    { id: '38', x: 866, y: 346 },
                    { id: '39', x: 750, y: 354 },
                    { id: '40', x: 587, y: 365 },
                    { id: '41', x: 446, y: 381 },
                    { id: '42', x: 768, y: 241 },
                    { id: '43', x: 874, y: 244 },
                    { id: '44', x: 810, y: 158 },
                    { id: '45', x: 880, y: 123 },
                    { id: '46', x: 895, y: 38 },
                    { id: '47', x: 981, y: 73 },
                    { id: '48', x: 1067, y: 72 },
                    { id: '49', x: 75, y: 585 }
                ].forEach((data, index) => {
                    const bombka = new Bombka(sheet, data.id);
                    bombka.anchor.set(.5);
                    bombka.position.set(data.x, data.y);

                    bombka.on('pointerdown', () => {
                        this.selectItem(index);
                    })

                    this.stage.addChild(bombka);
                    this.bombki.push(bombka);


                })

                this.selectItem(-1);
            }
        }
    }

    private selectItem(selectedIndex: number) {
        this.bombki.forEach((bombka, index) => {

            if (this.config.items[index].avaiable === false) {
                bombka.status = 'disabled';
            } else {
                bombka.status = (index === selectedIndex ? "selected" : 'idle');
            }

        })

        this.config.onItemSelected(selectedIndex);
    }

    public gameResize = () => {
        const nW = this.config.container.offsetWidth;
        const nH = nW * this.baseRatio;
        this.renderer.resize(nW, nH);
        this.scaleRatio = (nW / this.baseWidth);
        this.stage.scale.set(this.scaleRatio)
    }
}



