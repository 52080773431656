import { useMemo } from "react";
import useConfig from "./use-config";
import useNow from "./use-now";
import useTimeline from "./use-timeline";

export enum EVENT_PHASE {
    PRE_EVENT = 'PRE_EVENT',
    INTRO_LIVE = 'INTRO_LIVE',
    MAIN_EVENT = 'MAIN_EVENT',
    PRE_FINAL_LIVE = 'PRE_FINAL_LIVE',
    FINAL_LIVE = 'FINAL_LIVE',
    POST_EVENT = 'POST_EVENT',
}

export default function useEventPhase() {
    const {isLoading, timeline} = useTimeline();
    const {now} = useNow();
    const {forceEventPhase} = useConfig();

    const phase = useMemo(() => {
        if (isLoading) return null;
        if (forceEventPhase) return forceEventPhase;

        if (now < timeline.INTRO_LIVE_START.getTime()) {
            return EVENT_PHASE.PRE_EVENT;
        }

        if (now < timeline.INTRO_LIVE_END.getTime()) {
            return EVENT_PHASE.INTRO_LIVE;
        }

        if (now < timeline.SELL_END.getTime()) {
            return EVENT_PHASE.MAIN_EVENT;
        }

        if (now < timeline.FINAL_LIVE_EVENT_START.getTime()) {
            return EVENT_PHASE.PRE_FINAL_LIVE;
        }

        if (now < timeline.FINAL_LIVE_END.getTime()) {
            return EVENT_PHASE.FINAL_LIVE;
        }

        return EVENT_PHASE.POST_EVENT;


    }, [now, timeline, isLoading, forceEventPhase])

    return {
        phase,
        isLoading
    }
}
