const SIDES_COUNT = 8;
const PAGES_PER_SIDE = 10;
const ITEMS_PER_PAGE = 50;

const ITEMS_COUNT = SIDES_COUNT * PAGES_PER_SIDE * ITEMS_PER_PAGE;
const ITEMS_PER_SIDE = PAGES_PER_SIDE * ITEMS_PER_PAGE;

export {
    SIDES_COUNT,
    ITEMS_COUNT,
    PAGES_PER_SIDE,
    ITEMS_PER_SIDE,
    ITEMS_PER_PAGE,
}
