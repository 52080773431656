import React from 'react';
import { Link } from 'react-router-dom';
import PurchasedCounter from '../../components/counter';
import { getLoginRoute, getNewsRoute } from '../routes';

import './style.scss';

export default function HomePage() {
    return (
        <main>
            <div className="home-page">
                <div className="page-wrapper">
                    <nav>
                        <Link className='login' to={getLoginRoute()}>Zostań magenta dream makerem - <strong>kup bombkę</strong></Link>
                        <Link className='news' to={getNewsRoute()}>Aktualności</Link>
                    </nav>
                    <PurchasedCounter />
                </div>
            </div>
        </main>
    )
}
