import { SideData } from "../../../modules/items/hooks/use-side";
import ChoinkaApp from "./app";

export type AppConfig = {
    container: any,
    onSideShowed: (index: number) => void;
    onZoneClicked: (index: number) => void;
}

const BASE_WIDTH = 1920;
const BASE_HEIGHT = 1504;

export default function createApp(config: AppConfig) {

    const app = new ChoinkaApp(BASE_WIDTH, BASE_HEIGHT, 0x000000, config)

    config.container.appendChild(app.view);
    app.gameResize();

    // ====================== //

    return {
        nextPage: () => app.nextPage(),
        prevPage: () => app.prevPage(),
        setData: (data: SideData[]) => app.setData(data)
    };
}
