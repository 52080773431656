import { ConfigModuleAction, ConfigModuleState, CONFIG_MODULE_ACTION } from ".";


export default function ConfigReducer(state: ConfigModuleState, action: ConfigModuleAction): ConfigModuleState {

    const {type, payload} = action;

    switch(type) {
        case CONFIG_MODULE_ACTION.FETCH:
            return {
                ...state,
                isLoading: true
            }

        case CONFIG_MODULE_ACTION.FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: payload.user ? {
                    email: payload.user.email,
                    group: payload.user.type
                } : null,
                purchasedCount: payload.purchasedCount,
            }

        case CONFIG_MODULE_ACTION.SET_DEBUG_MODE:

            if (payload.mode === 'none') {
                return {
                    ...state,
                    debugMode: 'none'
                }
            }

            if (payload.mode === 'phase') {
                return {
                    ...state,
                    debugMode: 'phase',
                    forceEventPhase: payload.phase
                }
            }

            if (payload.mode === 'time') {
                return {
                    ...state,
                    debugMode: 'time'
                }
            }
    }

    return {
        ...state
    }
}
