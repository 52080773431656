import useItems from "./use-items";

export default function useItem(id: number) {
    const {items, isLoading} = useItems();

    const item = items.find(i=> i.id === id);

    return {
        isLoading,
        item
    }
}
