import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import DebugComponent from './components/debug';
import PageHeader from './header';
import useEventPhase, { EVENT_PHASE } from './modules/config/hooks/use-event-phase';
import useIsLogged from './modules/user/hooks/use-is-logged';
// import DebugComponent from './components/debug';
import BuyPage from './routes/buy';
import CatalogPage from './routes/catalog';
import FaqPage from './routes/faq';

import HomePage from './routes/home';
import LoginPage from './routes/login';
import MainViewPage from './routes/main-view';
import NewsPage from './routes/news';
import PreEventPage from './routes/pre-event';
import { getBuyRoute, getCatalogPageRoute, getFaqRoute, getLoginRoute, getMainViewRoute, getNewsRoute, getRulesRoute } from './routes/routes';
import RulesPage from './routes/rules';
import SellClosedPage from './routes/sell-closed';
import StreamingPage from './routes/streaming';

export default function App() {

    const { isLoading: isLoggegLoading, isLogged } = useIsLogged();
    const { isLoading: isEventPhaseLoading, phase } = useEventPhase();

    const isLoading = isEventPhaseLoading || isLoggegLoading;

    return (
        <>
            { phase === EVENT_PHASE.PRE_EVENT && !isLoading && <BrowserRouter>
                <Switch>
                    <Route path='/debug-console' component={DebugComponent} exect />
                    <Route component={PreEventPage} />
                </Switch>
            </BrowserRouter>
            }
            { phase === EVENT_PHASE.INTRO_LIVE && !isLoading && <BrowserRouter>
                <PageHeader />
                <Switch>
                    <Route path="/" exact>
                        <StreamingPage embedUrl='https://vimeo.com/event/526409/embed/9a15620bdd' />
                    </Route>
                    <Route path='/debug-console' component={DebugComponent} exect />

                    <Route path={getNewsRoute()} component={NewsPage} exact />
                    <Route path={getRulesRoute()} component={RulesPage} exact />
                    <Route path={getFaqRoute()} component={FaqPage} exact />

                    <Route>
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </BrowserRouter>
            }
            { phase === EVENT_PHASE.MAIN_EVENT && !isLoading && <>
                <BrowserRouter>
                    <PageHeader />
                    <Switch>
                        <Route path="/" component={HomePage} exact />
                        <Route path={getLoginRoute()} component={LoginPage} exact />

                        <Route path={getNewsRoute()} component={NewsPage} exact />
                        <Route path={getRulesRoute()} component={RulesPage} exact />
                        <Route path={getFaqRoute()} component={FaqPage} exact />

                        {isLogged && <Route path={getMainViewRoute()} component={MainViewPage} exact />}

                        {isLogged && <Route path={getCatalogPageRoute()} component={CatalogPage} exact />}
                        {isLogged && <Route path={getBuyRoute()} component={BuyPage} exact />}

                        <Route path='/debug-console' component={DebugComponent} exect />

                        <Route>
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </>
            }
            { phase === EVENT_PHASE.PRE_FINAL_LIVE && !isLoading && <BrowserRouter>
                <PageHeader />
                <Switch>
                    <Route path="/" component={HomePage} exact />

                    <Route path='/debug-console' component={DebugComponent} exect />

                    <Route path={getLoginRoute()} component={LoginPage} exact />

                    <Route path={getNewsRoute()} component={NewsPage} exact />
                    <Route path={getRulesRoute()} component={RulesPage} exact />
                    <Route path={getFaqRoute()} component={FaqPage} exact />



                    {isLogged && <Route path={getMainViewRoute()} component={SellClosedPage} exact />}
                    {isLogged && <Route path={getCatalogPageRoute()} component={SellClosedPage} exact />}
                    {isLogged && <Route path={getBuyRoute()} component={SellClosedPage} exact />}

                    <Route>
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </BrowserRouter>
            }
            { phase === EVENT_PHASE.FINAL_LIVE && !isLoading && <BrowserRouter>
                <PageHeader />
                <Switch>
                    <Route path='/debug-console' component={DebugComponent} exect />

                    <Route path={getNewsRoute()} component={NewsPage} exact />
                    <Route path={getRulesRoute()} component={RulesPage} exact />
                    <Route path={getFaqRoute()} component={FaqPage} exact />
                    <Route path="/"><StreamingPage embedUrl='https://vimeo.com/event/551850/embed' /></Route>
                </Switch>
            </BrowserRouter>
            }
            { phase === EVENT_PHASE.POST_EVENT && !isLoading && <BrowserRouter>
                <PageHeader />
                <Switch>
                    <Route path="/" component={HomePage} exact />

                    <Route path='/debug-console' component={DebugComponent} exect />

                    <Route path={getLoginRoute()} component={LoginPage} exact />

                    <Route path={getNewsRoute()} component={NewsPage} exact />
                    <Route path={getRulesRoute()} component={RulesPage} exact />
                    <Route path={getFaqRoute()} component={FaqPage} exact />



                    {isLogged && <Route path={getMainViewRoute()} component={SellClosedPage} exact />}
                    {isLogged && <Route path={getCatalogPageRoute()} component={SellClosedPage} exact />}
                    {isLogged && <Route path={getBuyRoute()} component={SellClosedPage} exact />}

                    <Route>
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </BrowserRouter>
            }
        </>
    )
}
