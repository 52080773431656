import { useMemo } from "react";
import { ITEMS_PER_PAGE, ITEMS_PER_SIDE, PAGES_PER_SIDE } from "..";
import useItems from "./use-items";

export type SideData = {
    index: number,
    total: number,
    avaiable: number,
}

export default function useSide(sideIndex: number) {
    const { items, isLoading } = useItems();

    const pages = useMemo(() => {
        if (!items) return [];

        return Array(PAGES_PER_SIDE)
            .fill(null)
            .map((_, index) => {
                return {
                    index: (PAGES_PER_SIDE * sideIndex) + index,
                    total: items
                        .slice(
                            sideIndex * ITEMS_PER_SIDE + ITEMS_PER_PAGE * index,
                            sideIndex * ITEMS_PER_SIDE + (index + 1) * ITEMS_PER_PAGE)
                        .length,
                    avaiable: items
                        .slice(
                            sideIndex * ITEMS_PER_SIDE + ITEMS_PER_PAGE * index,
                            sideIndex * ITEMS_PER_SIDE + (index + 1) * ITEMS_PER_PAGE)
                        .filter(i => i.avaiable)
                        .length
                }
            })
    }, [items, sideIndex])

    return {
        isLoading,
        pages: pages as SideData[]
    }
}
