import useConfig from "./use-config";
import useEventPhase, { EVENT_PHASE } from "./use-event-phase";

export default function usePurchasedCount() {
    const {isLoading: isLoading1, purchasedCount} = useConfig();
    const {isLoading: isLoading2, phase} = useEventPhase();

    const isLoading = isLoading1 && isLoading2;

    const overWrite = phase === EVENT_PHASE.PRE_FINAL_LIVE || phase === EVENT_PHASE.FINAL_LIVE || phase === EVENT_PHASE.POST_EVENT;

    return {
        isLoading,
        purchasedCount: overWrite ? 2138 : purchasedCount
    }
}
