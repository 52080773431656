import React from "react"
import { useForm } from "react-hook-form";
import { useCallback } from "react";
import useLogin from "../../modules/user/hooks/use-login";
import './style.scss';

type Props = {}

const LoginForm: React.FC<Props> = () => {

    const { register, handleSubmit } = useForm();
    const { login, isLoading, isError, reset } = useLogin();
    const onSubmit = useCallback((data) => {
        login({ email: data.email });
    }, [login]);

    const showLoginForm = !isError;
    const showLoginError = isError;

    return <>
        {showLoginForm && <div className="login-form">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="left">
                    <label>
                        <span>Wpisz swój e-mail:</span>
                        <input type="text" name='email' ref={register({ required: true })} />
                    </label>
                </div>
                <div className="right">
                    {!isLoading && <input type="submit" value="Zaloguj" />}
                </div>
            </form>
        </div>}
        {
            showLoginError && <div className="error-dialog">
                <h2>UPS! Wystąpił błąd logowania:</h2>
                <p>Pamiętaj o użyciu służbowego adresu</p>
                <button onClick={reset}>Zaloguj Ponownie</button>
            </div>
        }
    </>


}

export default LoginForm;
