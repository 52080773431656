import React from "react";
import useEventStart from "../../modules/config/hooks/use-event-start";
import './style.scss';

type Props = {

}

const PreEventPage: React.FC<Props> = () => {

    const { days, hours, minutes, seconds } = useEventStart()

    return <main className="pre-event-page">

        <header>
            <a href="/" className="t-mobile">Strona główna</a>
        </header>

        <div className="wrapper">
            <div className="text-container">
                <div className="text-container-wrapper">

                    <div className="column column-left">
                        <h2>
                            Dołącz do Magenta Dream Makersów!<br />
                            <strong>Stwórzmy razem choinkę marzeń!</strong>
                        </h2>

                        <div className="clock">
                            <span>{days} Dni</span>
                            <span>{hours} Godzin</span>
                            <span>{minutes} Minut</span>
                            <span>{seconds} Sekund</span>
                        </div>

                    </div>
                    <div className="column column-right">
                        <p><strong>Dlaczego marzeń?</strong> Bo to będzie najpiękniejsza choinka w te święta, dzięki której spełnią się marzenia podopiecznych Fundacji Dziecięca Fantazja i te, wybrane przez Ciebie.</p>
                        <p>Każda bombka na tym magentowym drzewku może skrywać właśnie Twoje charytatywne marzenie, cel który chcesz wesprzeć.</p>
                        <p><strong>Już od 6 grudnia</strong> będzie można kupić na stronie swoją wybraną bombkę, a do niej włożyć swój własny pomysł, na to jak i komu chcesz pomóc.</p>
                        <p>Podczas <strong>wielkiego finału</strong> wybierzemy 50 bombek i zrealizujemy zamknięte w nich marzenia.</p>
                        <p>Dodatkowo, każda zakupiona bombka to także zaproszenie do udziału w <strong>wyjątkowych świątecznych warsztatach</strong>. Bombkowo prawda?!</p>
                    </div>
                </div>
            </div>
        </div>
    </main >
}

export default PreEventPage;
