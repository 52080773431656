import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import useSide, { SideData } from "../../../modules/items/hooks/use-side";
import { getCatalogPageRoute } from "../../../routes/routes";
import createApp from "./create-app";

type Props = {}
type API = {
    nextPage: () => void;
    prevPage: () => void;
}

const Container = styled.div``;


const ChoinkaWidget = forwardRef<API, Props>((props, ref) => {
    const generatorContainer = useRef<HTMLDivElement>(null);
    const history = useHistory();

    const [index, setActiveSideIndex] = useState(1);

    const { pages } = useSide(index);

    const [appAPI, setAppAPI] = useState<{
        nextPage: () => void,
        prevPage: () => void,
        setData: (data: SideData[]) => void
    }>();

    useImperativeHandle(ref, () => ({
        nextPage() {
            if (appAPI) {
                appAPI.nextPage();
            }
        },
        prevPage() {
            if (appAPI) {
                appAPI.prevPage();
            }
        }
    }));

    useEffect(() => {
        const {nextPage, prevPage, setData} = createApp({
            container: generatorContainer.current,
            onSideShowed: (index: number) => {
                setActiveSideIndex(index)
            },
            onZoneClicked: (index: number) => {
                history.push(getCatalogPageRoute(index));
            }
        });

        setAppAPI({nextPage, prevPage, setData});
    }, [generatorContainer, setActiveSideIndex, history])

    useEffect(() => {
        if(appAPI) {
            appAPI.setData(pages)
        }
    }, [pages, appAPI])


    return <Container>
        <div ref={generatorContainer} className="gameContainer"></div>
    </Container>
});

export default ChoinkaWidget;
