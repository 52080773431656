import * as PIXI from 'pixi.js';
import { ChoinkaPage } from "../choinka-page";
export default class ChoinkaPage4 extends ChoinkaPage {
    constructor (resources: Partial<Record<string, PIXI.LoaderResource>>) {
        super(resources);

        if (resources['choinka-4']) {
            const mainSprite = new PIXI.Sprite(resources['choinka-4']?.texture)

            this.addChild(mainSprite);
        }

        [
            {
                textureId: 'zone-3-09.png',
                poly: new PIXI.Polygon([
                    56,0,
                    0,200,
                    131,200,
                    60, 0
                ]),
                x: 898,
                y: 182,
                popupOnLeft: false,
                popupLineStart: 990
            },

            {
                textureId: 'zone-3-08.png',
                poly: new PIXI.Polygon([
                    57,0,
                    0,222,
                    118,222,
                    118, 0
                ]),
                x: 841,
                y: 384,
                popupOnLeft: true,
                popupLineStart: 960
            },

            {
                textureId: 'zone-3-07.png',
                poly: new PIXI.Polygon([
                    0,0,
                    0,222,
                    100,222,
                    50, 0
                ]),
                x: 963,
                y: 384,
                popupOnLeft: false,
                popupLineStart: 1045
            },

            {
                textureId: 'zone-3-06.png',
                poly: new PIXI.Polygon([
                    55,0,
                    175,0,
                    175,216,
                    0, 216
                ]),
                x: 795,
                y: 608,
                popupOnLeft: true,
                popupLineStart: 960
            },

            {
                textureId: 'zone-3-05.png',
                poly: new PIXI.Polygon([
                    0,0,
                    0,216,
                    150,216,
                    100, 0
                ]),
                x: 963,
                y: 608,
                popupOnLeft: false,
                popupLineStart: 1105
            },

            {
                textureId: 'zone-3-04.png',
                poly: new PIXI.Polygon([
                    55,0,
                    234,0,
                    234,238,
                    0, 238
                ]),
                x: 726,
                y: 826,
                popupOnLeft: true,
                popupLineStart: 960
            },

            {
                textureId: 'zone-3-03.png',
                poly: new PIXI.Polygon([
                    0,0,
                    0,238,
                    200,238,
                    150, 0
                ]),
                x: 962,
                y: 826,
                popupOnLeft: false,
                popupLineStart: 1140
            },

            {
                textureId: 'zone-3-01.png',
                poly: new PIXI.Polygon([
                    50,0,
                    0,263,
                    175,263,
                    175, 0
                ]),
                x: 672,
                y: 1066,
                popupOnLeft: true,
                popupLineStart: 865
            },

            {
                textureId: 'zone-3-02.png',
                poly: new PIXI.Polygon([
                    0,0,
                    0,263,
                    190,263,
                    190, 0
                ]),
                x: 865,
                y: 1066,
                popupOnLeft: true,
                popupLineStart: 1060
            },

            {
                textureId: 'zone-3-00.png',
                poly: new PIXI.Polygon([
                    0,0,
                    0,263,
                    175,263,
                    110, 0
                ]),
                x: 1058,
                y: 1066,
                popupOnLeft: false,
                popupLineStart: 1215
            },
        ].forEach((d, index) => {
            this.addZone(d, index);
        });
    }
}
