export default function useTimeline() {
    const timeline = {
        INTRO_LIVE_START: new Date('2020-12-06T17:00:00.000Z'),
        INTRO_LIVE_END: new Date('2020-12-06T17:10:00.000Z'),
        SELL_END: new Date('2020-12-15T23:00:00.000Z'),
        FINAL_LIVE_EVENT_START: new Date('2020-12-17T11:30:00.000Z'),
        FINAL_LIVE_END: new Date('2020-12-17T14:00:00.000Z')
    }

    return {
        isLoading: false,
        timeline
    }
}
