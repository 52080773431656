import React from "react";
import { useParams } from "react-router";
import BuyForm from "../../components/buy-form";
import DialogScreen from "../../components/dialog";
import Loader from "../../components/loader";
import useItem from "../../modules/items/hooks/use-item";
import { useUser } from "../../modules/user/hooks/use-user";

import './style.scss';

type Props = {}

const BuyPage: React.FC<Props> = () => {

    const { id } = useParams<{ id: string }>();

    const { item, isLoading: isLoadingItems } = useItem(parseInt(id));
    const { user, isLoading: isLoadingUser } = useUser();
    const isLoading = isLoadingItems || isLoadingUser;

    return <>
    {!isLoading && item && user && <BuyForm id={parseInt(id)} user={user} />}
    {isLoading && <main className="buy-page page">
        <div className="buy-page-wrapper">
            <div className="page-content">
                <header>
                </header>
                <Loader />
            </div>

        </div>
    </main>}
    {!isLoading && (!item || !user) && <DialogScreen><h2>Coś poszło nie tak</h2></DialogScreen>}
    </>
}

export default BuyPage;
