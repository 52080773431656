import { useQuery } from "react-query";
import Network from "../../network";

export default function useItems() {
    const {data, isLoading, isSuccess} = useQuery('items', () => {
        return Network.getItems();
    }, {
        staleTime: Infinity
    });

    return {
        items: isSuccess ? (data || []) : [],
        isLoading
    }
}
