import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { News } from "../../modules/network";
import ScrollToMe from "../scroll-to-me";
import './style.scss';

type Props = {
    newsList: News[];
}

const NewsComponent: React.FC<Props> = ({ newsList }) => {

    const columnCount = 3;
    const itemsRef = useRef<(HTMLLIElement | null)[]>([]);
    const listRef = useRef<HTMLUListElement | null>(null);

    const [listWidth, setListWidth] = useState(0);

    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const selectedItem = newsList[selectedItemIndex];

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, newsList.length);
    }, [newsList]);

    const refCount = itemsRef.current.length;

    const listWithPosition = useMemo(() => {

        return newsList.map((news, index) => {

            if (refCount === 0)
                return {
                    item: news,
                    height: 0,
                    column: index % newsList.length
                };

            if (listWidth === 0) { }

            const ref = itemsRef.current[index];
            if (ref) {
                const height = ref.offsetHeight || 0;

                return {
                    item: news,
                    height,
                    column: 0
                }
            } else {
                return {
                    item: news,
                    height: 0,
                    column: 0
                }
            }
        })
            .reduce((prev: { item: News, column: number, height: number, position: number }[], next: { item: News, height: number }) => {

                const columnHeightList = prev.reduce((prev: number[], next: { item: News, column: number, height: number }) => {
                    prev[next.column] = prev[next.column] + next.height;

                    return prev;
                }, Array(columnCount).fill(0));

                const smallestColumnIndex = columnHeightList.indexOf(Math.min.apply(null, columnHeightList));


                return [
                    ...prev,
                    {
                        item: next.item,
                        height: next.height,
                        column: smallestColumnIndex,
                        position: columnHeightList[smallestColumnIndex]
                    }
                ]
            }, [])
    }, [newsList, columnCount, listWidth, refCount])

    const listHeight = useMemo(() => {
        const columnHeightList = listWithPosition.reduce((prev: number[], next: { item: News, column: number, height: number }) => {
            prev[next.column] = prev[next.column] + next.height;

            return prev;
        }, Array(columnCount).fill(0));

        return Math.max.apply(null, columnHeightList);
    }, [listWithPosition])

    const onResize = useCallback(() => {
        setListWidth(listRef.current?.offsetWidth || 0);
    }, [setListWidth]);

    useEffect(() => {
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [onResize])

    const closeItem = useCallback(() => {
        setSelectedItemIndex(-1);
    }, [setSelectedItemIndex])

    const openItem = useCallback((index: number) => {
        setSelectedItemIndex(index);
    }, [setSelectedItemIndex])

    return <div className="news-component">
        <h2>Aktualności</h2>

        <div className="list-container">
            {selectedItem && <article className="selected-item">
                <ScrollToMe behavior='smooth' />
                <div className="selected-item-wrapper">
                    <main dangerouslySetInnerHTML={{ __html: selectedItem.text }}></main>
                    <img src={selectedItem.contentImage} alt="" />
                    <div className="back-container">
                        <button className="back" onClick={closeItem}>Wróć</button>
                    </div>
                </div>
            </article>}

            {newsList?.length && <ul className="news-list" ref={listRef} style={{minHeight: listHeight + 30}}>

                {
                    listWithPosition.map((newsWithPosition, index) => {
                        const news = newsWithPosition.item
                        return <li className="news-list-item" key={index} ref={el => itemsRef.current[index] = el} style={{
                            transform: `translateX(${newsWithPosition.column * 117}%) translateY(${newsWithPosition.position}px)`
                        }}>
                            <div className="wrapper">
                                <div className="wrapper-2">
                                    <h3>{news.title}</h3>
                                    <button className="more" onClick={() => openItem(index)}>Czytaj więcej</button>
                                </div>
                                <div className="wrapper-3">
                                    <div className="img-container" style={{ paddingTop: `${news.listImageRatio * 100}%` }}>
                                        <img src={news.listImage} alt={news.title} />
                                    </div>
                                </div>
                            </div>
                        </li>
                    })
                }
            </ul>
            }

        </div>

    </div>
}

export default NewsComponent;
