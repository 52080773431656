import * as PIXI from 'pixi.js'
import { SideData } from '../../../modules/items/hooks/use-side';
import { ChoinkaPage } from './choinka-page';
import { AppConfig } from './create-app';
import ChoinkaPage1 from './pages/page-1';
import ChoinkaPage2 from './pages/page-2';
import ChoinkaPage3 from './pages/page-3';
import ChoinkaPage4 from './pages/page-4';
import ChoinkaPage5 from './pages/page-5';
import ChoinkaPage6 from './pages/page-6';
import ChoinkaPage7 from './pages/page-7';
import ChoinkaPage8 from './pages/page-8';

export default class ChoinkaApp extends PIXI.Application {
    private baseWidth: number;
    private baseHeight: number;
    private baseRatio: number;
    private scaleRatio = 1;
    private resources?: Partial<Record<string, PIXI.LoaderResource>>;

    private pages: ChoinkaPage[] = [];
    private activePage = 0;

    constructor(width: number, height: number, backgroundColor: number, private config: AppConfig) {
        super({
            width,
            height,
            backgroundColor
        });

        this.baseHeight = height;
        this.baseWidth = width;
        this.baseRatio = height / width;

        window.addEventListener('resize', this.gameResize)
        this.gameResize();

        this.loadTextures().then((resources) => {
            this.resources = resources;
            this.initGame()
        })
    }

    private loadTextures(): Promise<Partial<Record<string, PIXI.LoaderResource>>> {
        return new Promise((resolve) => {
            [
                ['instruction', '/images/choinka/instruction.png'],
                ['arrow', '/images/choinka/arrow.png'],
                ['choinka-1', '/images/choinka/choinka-1.png'],
                ['choinka-2', '/images/choinka/choinka-2.png'],
                ['choinka-3', '/images/choinka/choinka-3.png'],
                ['choinka-4', '/images/choinka/choinka-4.png'],
                ['choinka-5', '/images/choinka/choinka-5.png'],
                ['choinka-6', '/images/choinka/choinka-6.png'],
                ['choinka-7', '/images/choinka/choinka-7.png'],
                ['choinka-8', '/images/choinka/choinka-8.png'],
                ['zones', '/images/choinka/zones.json']

            ].forEach(([key, src]) => {
                this.loader.add(key, src);
            })

            this.loader.load((loader, resource) => {
                resolve(resource)
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })
        })

    }

    private initGame() {

        if (this.resources) {
            [
                ChoinkaPage1,
                ChoinkaPage2,
                ChoinkaPage3,
                ChoinkaPage4,
                ChoinkaPage5,
                ChoinkaPage6,
                ChoinkaPage7,
                ChoinkaPage8,
            ].forEach(C => {
                if (this.resources) {
                    const page = new C(this.resources);

                    this.stage.addChild(page);
                    this.pages.push(page);

                    page.on('zoneclick', ({id}: {id: number}) => {
                        this.config.onZoneClicked(id)
                    })
                }
            });

            this.showPage(0);
        }

        if (this.resources?.instruction) {
            const rules = new PIXI.Sprite(this.resources.instruction.texture);

            rules.position.set(200,100);

            this.stage.addChild(rules);
        }

        if (this.resources?.arrow) {
            const left = new PIXI.Sprite(this.resources.arrow.texture);
            const right = new PIXI.Sprite(this.resources.arrow.texture);

            left.anchor.set(.5);
            right.anchor.set(.5);

            right.rotation = Math.PI;

            left.position.set(230,this.baseHeight / 2);
            right.position.set(this.baseWidth - 230,this.baseHeight / 2);

            left.interactive = true;
            right.interactive = true;

            left.cursor = 'pointer';
            right.cursor = 'pointer';

            left.hitArea = new PIXI.Rectangle(-40, -40, 80, 80);
            right.hitArea = new PIXI.Rectangle(-40, -40, 80, 80);

            left.on('click', () => {
                this.prevPage()
            });

            right.on('click', () => {
                this.nextPage()
            });

            this.stage.addChild(left);
            this.stage.addChild(right);
        }
    }

    private showPage(index: number) {
        // debugger;
        this.pages.forEach(p => {
            p.visible = false;
        });

        this.pages[index].visible = true;

        this.pages.forEach((p, index) => {
        });

        this.config.onSideShowed(index);
    }

    public gameResize = () => {
        const nW = this.config.container.offsetWidth;
        const nH = nW * this.baseRatio;
        this.renderer.resize(nW, nH);
        this.scaleRatio = (nW / this.baseWidth);
        this.stage.scale.set(this.scaleRatio)
    }

    public nextPage() {
        this.activePage = (this.activePage + 1) % this.pages.length;
        this.showPage(this.activePage);
    }

    public prevPage() {
        this.activePage = (this.activePage - 1 + this.pages.length) % this.pages.length;
        this.showPage(this.activePage);
    }

    public setData(pageData: SideData[]) {
        this.pages.forEach(p => {
            p.setData(pageData);
        })
    }
}



