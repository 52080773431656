import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Item } from "../../../modules/network";
import { getBuyRoute, getMainViewRoute } from "../../../routes/routes";
import createApp from "./create-app";
import './style.scss';

type Props = {
    items: Item[]
}
type API = {}

const Container = styled.div``;


const BombkiWidget = forwardRef<API, Props>(({ items }, ref) => {
    const generatorContainer = useRef<HTMLDivElement>(null);
    const history = useHistory();

    const [index, setActiveItem] = useState(-1);
    const selectedItem = useMemo(() => {
        return items[index];
    }, [index, items])

    useImperativeHandle(ref, () => ({}));

    useEffect(() => {
        const container = generatorContainer.current;
        const {destroy} = createApp({
            container,
            items,
            onItemSelected: (index: number) => {
                setActiveItem(index)
            }
        });

        return () => {
            destroy();
            if (container) {
                container.innerHTML = '';
            }
        }

    }, [generatorContainer, setActiveItem, history, items])

    return <Container>
        <div ref={generatorContainer} className="gameContainer"></div>
        <div className="bombki-legend">
            <div className="primary-link back-link">
                <Link to={getMainViewRoute()}>Wróć</Link>
            </div>
            <div className="icons">
                <div className="active"><span>Dostepna</span></div>
                <div className="selected"><span>Wybrana</span></div>
                <div className="disabled"><span>Kupiona</span></div>
            </div>
            <div className="primary-link buy-link">
                {selectedItem && <Link to={getBuyRoute(selectedItem.id)}>Kupuję</Link>}
            </div>
            <div className="second-links">
                <div className="back-link">
                    <Link to={getMainViewRoute()}>Wróć</Link>
                </div>
                <div className="buy-link">
                    {selectedItem && <Link to={getBuyRoute(selectedItem.id)}>Kupuję</Link>}
                </div>
            </div>
        </div>

    </Container>
});

export default BombkiWidget;
