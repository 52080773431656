import * as PIXI from 'pixi.js'
import Bombka from './bombka';
import { AppConfig } from './create-app';

export default class BombkiApp extends PIXI.Application {
    private baseWidth: number;
    private baseHeight: number;
    private baseRatio: number;
    private scaleRatio = 1;
    private resources?: Partial<Record<string, PIXI.LoaderResource>>;

    constructor(width: number, height: number, backgroundColor: number, private config: AppConfig) {
        super({
            width,
            height,
            transparent: true
        });

        this.baseHeight = height;
        this.baseWidth = width;
        this.baseRatio = height / width;

        window.addEventListener('resize', this.gameResize)
        this.gameResize();

        this.loadTextures().then((resources) => {
            this.resources = resources;
            this.initGame()
        })
    }

    private loadTextures(): Promise<Partial<Record<string, PIXI.LoaderResource>>> {
        return new Promise((resolve) => {

            [
                ['background', '/images/bombki/widget-background-2.png'],
                ['bombki', '/images/bombki/bombki2.json']
            ].forEach(([key, src]) => {
                this.loader.add(key, src);
            })

            this.loader.load((loader, resource) => {
                resolve(resource)
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })
        })

    }

    private bombki: Bombka[] = [];

    private initGame() {
        if (this.resources?.background) {
            const sprite = new PIXI.Sprite(this.resources.background.texture);

            this.stage.addChild(sprite);
        }


        if (this.resources?.bombki) {
            const sheet = this.resources?.bombki.spritesheet;


            if (sheet) {
                [
                    { id: '00', x: 341, y: 226 },
                    { id: '01', x: 438, y: 176 },
                    { id: '02', x: 441, y: 83 },
                    { id: '03', x: 560, y: 46 },
                    { id: '04', x: 511, y: 106 },
                    { id: '05', x: 489, y: 182 },
                    { id: '06', x: 582, y: 115 },
                    { id: '07', x: 637, y: 42 },
                    { id: '08', x: 721, y: 80 },
                    { id: '09', x: 783, y: 98 },
                    { id: '10', x: 788, y: 153 },
                    { id: '11', x: 725, y: 155 },
                    { id: '12', x: 648, y: 120 },
                    { id: '13', x: 560, y: 183 },
                    { id: '14', x: 499, y: 251 },
                    { id: '15', x: 659, y: 196 },
                    { id: '16', x: 733, y: 220 },
                    { id: '17', x: 860, y: 230 },
                    { id: '18', x: 760, y: 310 },
                    { id: '19', x: 820, y: 352 },
                    { id: '20', x: 725, y: 370 },
                    { id: '21', x: 630, y: 285 },
                    { id: '22', x: 605, y: 390 },
                    { id: '23', x: 518, y: 363 },
                    { id: '24', x: 455, y: 313 },
                    { id: '25', x: 435, y: 280 },
                    { id: '26', x: 408, y: 268 },
                    { id: '27', x: 376, y: 396 },
                    { id: '28', x: 477, y: 446 },
                    { id: '29', x: 537, y: 436 },
                    { id: '30', x: 267, y: 510 },
                    { id: '31', x: 237, y: 617 },
                    { id: '32', x: 291, y: 602 },
                    { id: '33', x: 363, y: 621 },
                    { id: '34', x: 339, y: 531 },
                    { id: '35', x: 391, y: 528 },
                    { id: '36', x: 436, y: 520 },
                    { id: '37', x: 492, y: 513 },
                    { id: '38', x: 532, y: 619 },
                    { id: '39', x: 663, y: 503 },
                    { id: '40', x: 700, y: 635 },
                    { id: '41', x: 790, y: 580 },
                    { id: '42', x: 825, y: 648 },
                    { id: '43', x: 888, y: 575 },
                    { id: '44', x: 938, y: 635 },
                    { id: '45', x: 858, y: 515 },
                    { id: '46', x: 798, y: 501 },
                    { id: '47', x: 453, y: 613 },
                    { id: '48', x: 787, y: 419 },
                    { id: '49', x: 958, y: 453 }
                ].forEach((data, index) => {
                    const bombka = new Bombka(sheet, data.id);
                    bombka.anchor.set(.5);
                    bombka.position.set(data.x, data.y);

                    bombka.on('pointerdown', () => {
                        this.selectItem(index);
                    })

                    this.stage.addChild(bombka);
                    this.bombki.push(bombka);


                })

                this.selectItem(-1);
            }
        }
    }

    private selectItem(selectedIndex: number) {
        this.bombki.forEach((bombka, index) => {

            if (this.config.items[index].avaiable === false) {
                bombka.status = 'disabled';
            } else {
                bombka.status = (index === selectedIndex ? "selected" : 'idle');
            }

        })

        this.config.onItemSelected(selectedIndex);
    }

    public gameResize = () => {
        const nW = this.config.container.offsetWidth;
        const nH = nW * this.baseRatio;
        this.renderer.resize(nW, nH);
        this.scaleRatio = (nW / this.baseWidth);
        this.stage.scale.set(this.scaleRatio)
    }
}



