export function getHomeRoute() {
    return '/';
}

export function getMainViewRoute() {
    return '/choinka'
}

export function getZoneViewRoute(id: string = ':id') {
    return `/zone/${id}`;
}

export function getCatalogPageRoute(page: number | ':page' = ':page') {
    return `/page/${page}`;
}

export function getBuyRoute(id: number | ':id' = ':id') {
    return `/buy/${id}`;
}

export function getNewsRoute() {
    return '/news';
}
export function getRulesRoute() {
    return '/rules';
}
export function getFaqRoute() {
    return '/faq';
}
export function getLoginRoute() {
    return '/login';
}
