import moment from "moment";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { CONFIG_MODULE_ACTION, useConfigModuleDispatch, useConfigModuleState } from "../../modules/config";
import useEventPhase, { EVENT_PHASE } from "../../modules/config/hooks/use-event-phase";
import useNow from "../../modules/config/hooks/use-now";
import useIsLogged from "../../modules/user/hooks/use-is-logged";
import { useUser } from "../../modules/user/hooks/use-user";

type Props = {}

const Container = styled.div`
    background: white;

    label {
        display: block;
    }

    form,
    .form-container {
        border: 1px solid black;
        padding: 20px;
        margin: 20px;
    }
`

const DebugComponent: React.FC<Props> = () => {

    const { isLogged } = useIsLogged();
    const { user } = useUser();
    const dispatch = useConfigModuleDispatch();
    const { debugMode, forceEventPhase } = useConfigModuleState();

    const { register, watch, handleSubmit } = useForm();
    const { now, setDelta, serverNow } = useNow();



    const { phase } = useEventPhase();

    const debugModeValue = watch('debugMode');

    const onSubmit = useCallback((data) => {
        if (data.debugMode === 'none') {
            dispatch({
                type: CONFIG_MODULE_ACTION.SET_DEBUG_MODE,
                payload: {
                    mode: 'none'
                }
            });
            setDelta(0);
        }

        if (data.debugMode === 'phase') {
            dispatch({
                type: CONFIG_MODULE_ACTION.SET_DEBUG_MODE,
                payload: {
                    mode: 'phase',
                    phase: data.forcePhase
                }
            });
            setDelta(0);
        }

        if (data.debugMode === 'time') {

            dispatch({
                type: CONFIG_MODULE_ACTION.SET_DEBUG_MODE,
                payload: {
                    mode: 'time',
                }
            });
            const date = new Date(`${data.date} ${data.time}`);
            setDelta(date.getTime() - serverNow);
        }
    }, [setDelta, dispatch, serverNow])

    return <Container>
        <h3>DEBUG TOOL</h3>
        <div className="form-container">
            <h4>Informacje o userze</h4>
            <p>Zalogowany: {isLogged ? 'true' : 'false'}</p>
            <p>User: {JSON.stringify(user)}</p>
        </div>

        <div className="form-container">
            <h4>Informacje o evencie</h4>
            <p>Aktualna faza: {phase}</p>
            <p>Aktualny czas: {(new Date(now).toLocaleString())}</p>
        </div>


        <form onSubmit={handleSubmit(onSubmit)}>
            <h4>Tryby testowe</h4>
            <div className='form-container'>
                <h5>Wybierz tryb testowy {debugMode}</h5>
                <label>
                    <input type='radio' name='debugMode' value='none' defaultChecked={debugMode === 'none'} ref={register} /> Wyłącz tryb testowy
                </label>
                <label>
                    <input type='radio' name='debugMode' value='phase' defaultChecked={debugMode === 'phase'} ref={register} /> Chcę ustawić fazę eventu
                </label>
                <label>
                    <input type='radio' name='debugMode' value='time' defaultChecked={debugMode === 'time'} ref={register} /> Chcę zmienić godzinę na zegarze
                </label>
            </div>

            {debugModeValue === 'phase' &&
                <div className='form-container'>
                    <h5>Jaką fazę projketu chcesz zasymulować?</h5>
                    <label>
                        <input type='radio' name='forcePhase' defaultChecked={forceEventPhase === EVENT_PHASE.PRE_EVENT} value={EVENT_PHASE.PRE_EVENT} ref={register} /> Przed eventem
                    </label>
                    <label>
                        <input type='radio' name='forcePhase' defaultChecked={forceEventPhase === EVENT_PHASE.INTRO_LIVE} value={EVENT_PHASE.INTRO_LIVE} ref={register} /> Live na starcie
                    </label>
                    <label>
                        <input type='radio' name='forcePhase' defaultChecked={forceEventPhase === EVENT_PHASE.MAIN_EVENT} value={EVENT_PHASE.MAIN_EVENT} ref={register} /> Event główny
                    </label>
                    <label>
                        <input type='radio' name='forcePhase' defaultChecked={forceEventPhase === EVENT_PHASE.PRE_FINAL_LIVE} value={EVENT_PHASE.PRE_FINAL_LIVE} ref={register} /> Przed finałem (sprzedarz zamknięta)
                    </label>
                    <label>
                        <input type='radio' name='forcePhase' defaultChecked={forceEventPhase === EVENT_PHASE.FINAL_LIVE} value={EVENT_PHASE.FINAL_LIVE} ref={register} /> Finałowy live
                    </label>
                    <label>
                        <input type='radio' name='forcePhase' defaultChecked={forceEventPhase === EVENT_PHASE.POST_EVENT} value={EVENT_PHASE.POST_EVENT} ref={register} /> Po evencie
                    </label>
                </div>
            }

            {debugModeValue === 'time' &&
                <div className='form-container'>
                    <h5>Jaką godzinę chcesz zasymulować?</h5>
                    <input type="date" name="date" ref={register} defaultValue={moment(now).format('YYYY-MM-DD')} />
                    <input type="time" name="time" defaultValue={moment(now).format('HH:mm')} ref={register} />
                </div>
            }

            <input type="submit" value="Zastosuj" />
        </form>


    </Container>
}

export default DebugComponent;
