import React from "react";
import { Link } from "react-router-dom";
import './style.scss';

type Props = {}

const RulesPage: React.FC<Props> = () => {

    return <div className="rules-page">
        <div className="page-wrapper">
            <div className="page-content">
                <h1>Regulamin Akcji<br />T-MOBILE Magenta Dream Makers</h1>
                <h2>(dalej: „Akcja”, „Regulamin”)</h2>

                <ol>
                    <li>Akcja odbędzie się w dniach 6-17 grudnia 2020 r. Akcja organizowana jest przez T-Mobile Polska S.A., z siedzibą w Warszawie (02-674), przy ul.{'\u00A0'}Marynarskiej{'\u00A0'}12 (dalej: „Organizator” lub „T-Mobile”) . Celem akcji jest integracja pracowników i współpracowników Organizatora z całej Polski.</li>
                    <li>W akcji mogą uczestniczyć osoby, które zarejestrowały się poprzez system rejestracji na platformie https://magentadreammakers.pl (dalej: „Platforma”) udostępnionej w intranecie T-Mobile lub w inny sposób dopuszczony przez Organizatora i spełniają wymagania nałożone Regulaminem.</li>
                    <li>Akcja polega na zakupie bombek (w cenie 5zł, 20zł lub dowolnie wpisana kwota). Za zakup bombki Uczestnik może wziąć udział w jednym z trzech proponowanych przez Organizatora wydarzeń: zamkniętych webinarów z gwiazdami. Każda z bombek ma nadany indywidualny numer. Uczestnik może nabyć maksymalnie 3 bombki, co jest równeznaczne z uzyskaniem  prawa do wzięcia udziału w każdym z proponowanych przez Organizatora wydarzeń (max 3 wydarzenia) .</li>
                    <li>Zadeklarowaną kwotę należy wpłacić  na numer konta Organizatora (59 1240 1109 1111 0010 2508 8635) podając w tytule przelewu swój numer ID, imię, nazwisko oraz hasło „MAGENTA DREAM MAKERS” (przykład: 123456, Jan Kowalski, MAGENTA DREAM MAKERS).</li>
                    <li>Kwota zebrana z zakupu bombek zostanie przekazana przez Organizatora jako darowizna na rzecz Fundacji Dziecięca Fantazja.</li>
                    <li>Każdy uczestnik otrzymuje możliwość umieszczenia w bombce  życzenia. Opis życzenia i jego cel musi nosić znamiona dobroczynności. Życzenie zostanie zrealizowane przez Centrum Wolontariatu ze środków przekazanych przez Organizatora tytułem darowizny. Środki te nie będą pochodziły ze sprzedaży bombek. Każde życzenie  ma przypisany cel charytatywny o wartości do 4000 złotych.</li>
                    <li>W celu unikniecia jakichkolwiek wątpliwości na potrzeby niniejszej Akcji ustala się, iż cel musi nosić znamiona dobroczynności, a bezpośrednim obdarowanym może być organizacja pożytku publicznego, stowarzyszenie  czy też inna osoba, która znajduje się w trudnej sytuacji finansowej.</li>
                    <li>Liczba życzeń, które zostaną zrealizowane jest ograniczona do 50. Każdy Uczestnik ma prawo do zakupu maksymalnie 3 bombek.</li>
                    <li>Organizator zastrzega sobie prawo odrzucenia życzeń niespełniających wskazanych w niniejszym Regulaminie kryteriów.</li>
                    <li>Każda osoba, która skorzysta z przysługującego jej prawa i zgłosi życzenie otrzyma drogą mailową link do specjalnego, świątecznego quizu na temat akcji Magenta Dream Makers. Quiz będzie dostępny do rozwiązania wyłącznie w dniu 16 grudnia 2020 r., o czym osoba  zostanie poinformowana w komunikacji mailowej wysłanej bezpośrednio na jej adres e-mail wskazany przy zakupie bombki.</li>
                    <li>Quiz będzie dostępny w dniu 16 grudnia 2020 r. w  godzinach od 00:01 do 23:59 tego samego dnia.</li>
                    <li>Przed rozpoczęciem quizu każda osoba będzie musiała podać numer swojej bombki.</li>
                    <li>O zwycięstwie zadecyduje liczba poprawnych opowiedzi udzielonych w quizie. W przypadku uzyskania takiej samej liczby punktów przez więcej niż 50 osób  o wygranej zadecyduje szybkość rozwiązania quizu.</li>
                    <li>Podczas finału, który odbędzie się 17 grudnia 2020 r. ogłoszona zostanie lista 50 życzeń oraz ich autorów wskazanych wyłącznie z imienia i lokalizacji miejsca zatrudnienia. Cele charytatywne przypisane do życzeń zostaną zrealizowane przez T-Mobile, we współpracy z Centrum Wolontariatu.</li>
                    <li>Centrum Wolontaritu zrealizuje cel przy pomocy Wolontariuszy. Każda z osób, której zgłoszone życzenie zostanie w ramach tej Akcji zrealizowane może wziąć aktywny udział podczas jej realizacji na zasadach ustalonych odrębnie. Uprawnienie to przysługuje także innym uczestnikom Akcji.</li>
                    <li>Uczestnicy przyjmują do wiadomości, iż Organizator uprawniony jest do utrwalenia Akcji.</li>
                    <li>Organizator przewiduje możliwość przerwania bądź odwołania Akcji oraz zmiany jej przebiegu pod warunkiem poinformowania o tym Uczestników. O formie zawiadomienia Uczestników o tym fakcie lub podania tego do publicznej wiadomości decyduje wyłącznie Organizator.</li>
                    <li>Regulamin jest dostępny na stronie dedykowanej Akcji w intranecie T-Mobile oraz na Platformie.</li>
                    <li>Udział w Akcji oznacza akceptację warunków Regulaminu.</li>
                    <li>Osoby biorące bezpośredni udział w organizacji wydarzenia zostają wykluczone z uczestnictwa w quizie.</li>
                </ol>

                <nav>
                    <Link to="/">Wróć</Link>
                </nav>
            </div>
        </div>
    </div>
}

export default RulesPage;
