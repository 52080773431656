import React from "react";

import './style.scss';

type Props = {}

const DialogScreen: React.FC<Props> = ({ children }) => {
    return <div className="dialog-screen">
        <div className="container">
            <div className="content">
                {children}
            </div>
        </div>
    </div>
}

export default DialogScreen;
