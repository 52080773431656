import React from "react";
import { Link } from "react-router-dom";
import './style.scss';

type Props = {}

const FaqPage: React.FC<Props> = () => {

    return <div className="faq-page">
        <div className="page-wrapper">
            <div className="page-content">
                <h1>FAQ</h1>

                <dl>
                    <dt>Kto może wziąć udział w akcji?</dt>
                    <dd>W akcji Magenta Dream Makers może wziąć udział każdy pracownik T-Mobile Polska oraz osoby zaproszone przez T-Mobile.</dd>

                    <dt>Ile bombek mogę kupić?</dt>
                    <dd>Każdy, kto chce wziąć udział w akcji Magenta Dream Makers może kupić 3 bombki. </dd>

                    <dt>Jaka jest cena bombki?</dt>
                    <dd>Ceny bombek to 5zł, 20 zł lub inna preferowana kwota wyższa niż 20 zł.</dd>

                    <dt>Jak mam zapłacić za bombkę?</dt>
                    <dd>Po zakupie bombki na Twojego maila zostanie wysłany numer konta, na który należy zrobić przelew.</dd>

                    <dt>Do kiedy można kupować bombki?</dt>
                    <dd>Sprzedaż bombek kończy się w 15 grudnia 2020 r. o godz. 23:59 lub w momencie wyczerpania bombek.</dd>

                    <dt>Czy otrzymam zakupioną bombkę?</dt>
                    <dd>Bombki na choince są elementami scenograficznymi, które nie będą wysyłane.</dd>

                    <dt>Kiedy odbywają się warsztaty?</dt>
                    <dd>Warsztaty odbywają się w dniach 16 i 18 grudnia. Szczegółowe informacje na temat wybranych warsztatów oraz link do spotkania dostaniesz na swój służbowy adres e-mail.</dd>
                </dl>

                <nav>
                    <Link to="/">Wróć</Link>
                </nav>
            </div>
        </div>
    </div>
}

export default FaqPage;
