import React from "react";
import { Link } from "react-router-dom";
import PurchasedCounter from "../../components/counter";
import NewsComponent from "../../components/news"
import useNewsList from "../../modules/news/hooks/use-news-list";
import { getLoginRoute } from "../routes";

import './style.scss';

type Props = {}

const NewsPage: React.FC<Props> = () => {

    const { isLoading, newsList } = useNewsList();

    return <main>
        <div className="news-page">
            <div className="page-wrapper">
                <nav>
                    <Link className='login' to={getLoginRoute()}>Zostań magenta dream makerem - <strong>kup bombkę</strong></Link>
                </nav>
                <PurchasedCounter />

            </div>
        </div>
        <div className="news-list-container">
            <div className="page-wrapper">
                {!isLoading && newsList.length && <NewsComponent newsList={newsList} />}
            </div>
        </div>
    </main>
}

export default NewsPage;
