import { useQuery } from "react-query";
import Network from "../../network";

export default function useNewsList() {
    const {data, isLoading} = useQuery('news', () => {
        return Network.getNews();
    }, {
        staleTime: Infinity
    });

    return {
        newsList: data || [],
        isLoading
    }
}
