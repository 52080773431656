import { useCallback } from "react";
import { queryCache } from "react-query";
import Network from "../../network";

export default function useLogout() {
    return useCallback(() => {
        Network.logout()
            .then(() => {
                queryCache.invalidateQueries('config');
                queryCache.invalidateQueries('items');
            })
    }, [])
}
