import * as PIXI from 'pixi.js';
import { SideData } from '../../../modules/items/hooks/use-side';
import Zone from './pages/zone';



export class ChoinkaPage extends PIXI.Container {

    debugText = new PIXI.Text('', {
        fill: 0xff00dd
    });

    private popupText = new PIXI.Text('', {
        fill: 0xbe1570,
        fontSize: 33,
        fontFamily: 'tele-groteskhal'
    });

    private popupText2 = new PIXI.Text('', {
        fill: 0xffffff,
        fontSize: 33,
        fontFamily: 'tele-groteskhal'
    });
    private popupGfx = new PIXI.Graphics();

    constructor(private resources: Partial<Record<string, PIXI.LoaderResource>>) {
        super();

        this.sortableChildren = true;

        // this.addChild(this.debugText);
        this.debugText.zIndex = 1000;

        this.addChild(this.popupText);
        this.popupText.zIndex = 1000;
        this.popupText.anchor.set(1, 1);

        this.addChild(this.popupText2);
        this.popupText2.zIndex = 1000;
        this.popupText2.anchor.set(1, 1);

        this.addChild(this.popupGfx);
        this.popupGfx.zIndex = 1000;
    }

    private data: SideData[] = [];

    public setData(data: SideData[]) {
        this.data = data;
        this.debugText.text = this.data.map(d => ` ${d.avaiable} `).join(';');
    }

    protected showInfo(index: number, y: number, leftSide: boolean, x: number) {
        if (this.data[index]) {
            this.popupText.text = `${this.data[index].avaiable}`;
            this.popupText.position.set(1255, y);
            this.popupText.visible = true;

            this.popupText2.text = `DOSTĘPNYCH BOMBEK`;
            this.popupText2.position.set(1566, y);
            this.popupText2.visible = true;

            this.popupGfx.beginFill(0xbe1570);
            this.popupGfx.drawRoundedRect(x, y + 2, 1566 - x, 6, 3)
            this.popupGfx.endFill();
        }
    }

    protected hideInfo() {
        this.popupText.visible = false;
        this.popupText2.visible = false;

        this.popupGfx.clear();
    }

    protected onZoneClicked(index: number) {
        if (this.data[index]) {

            this.emit('zoneclick', { id: this.data[index].index })
        }
    }

    protected addZone(d: {
        textureId: string,
        poly: PIXI.Polygon,
        x: number,
        y: number,
        popupOnLeft: boolean,
        popupLineStart: number
    }, index: number) {
        // if (index === 0) {
        //     return;
        // }

        if (this.resources?.zones) {
            const zone = new Zone(
                this.resources?.zones.textures?.[d.textureId] || PIXI.Texture.EMPTY,
                d.poly
            )

            zone.on('pointerover', () => {
                this.showInfo(index, zone.position.y + zone.height / 2, d.popupOnLeft, d.popupLineStart);
            });

            zone.on('pointerout', () => {
                this.hideInfo();
            });

            zone.position.set(d.x, d.y)

            zone.on('pointerdown', () => {
                this.onZoneClicked(index);
            })

            this.addChild(zone);
        }
    }
}
