import { useMemo } from "react";
import { ITEMS_PER_PAGE } from "..";
import useItems from "./use-items";

export default function usePage(pageIndex: number) {
    const { items, isLoading } = useItems();

    const pageItems = useMemo(() => {
        if (!items) return [];

        return items
            .slice(
                pageIndex * ITEMS_PER_PAGE,
                (pageIndex + 1) * ITEMS_PER_PAGE
            );
    }, [items, pageIndex]);

    return {
        isLoading,
        items: pageItems,
        total: pageItems.length,
        avaiable: pageItems.filter(i => i.avaiable).length
    }
}
