import useConfig from "../../config/hooks/use-config";

export function useUser() {
    const {isLoading, user} = useConfig();

    return {
        isLoading,
        user
    }
}
