import React from "react";
import { Link } from "react-router-dom";
import useIsLogged from "./modules/user/hooks/use-is-logged";
import useLogout from "./modules/user/hooks/use-logout";
import { getNewsRoute, getRulesRoute, getFaqRoute } from "./routes/routes";

type Props = {}

const PageHeader: React.FC<Props> = () => {
    const { isLogged } = useIsLogged();
    const logout = useLogout();

    return <header className="page-header">
        <div className="page-wrapper page-header-wrapper">
            <Link to="/" className="t-mobile">T-mobile</Link>
            <nav>
                <Link to={getNewsRoute()}>Aktualności</Link>
                <Link to={getRulesRoute()}>Regulamin</Link>
                <Link to={getFaqRoute()}>FAQ</Link>
                {isLogged && <button onClick={() => { logout() }}>Wyloguj</button>}
            </nav>
        </div>
    </header>
}

export default PageHeader;
